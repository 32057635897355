import { fetchAuthSession } from 'aws-amplify/auth';
async function computeSHA256(message) {
    const msgBuffer = new TextEncoder().encode(message); // Encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer); // Hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // Convert bytes to hex string
    return hashHex;
}

async function computeHMAC(lambdaUrl, apiUrl, voucherCode, body, method = 'GET') {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    var bodySHA256 = '';
    if(body){
        bodySHA256 = await computeSHA256(JSON.stringify(body));
    }

    try {
        const response = await fetch(lambdaUrl, {
            method: 'GET', // This should likely be method, but make sure your lambda supports it
            headers: {
                'Authorization': idToken,
                'X-Url': apiUrl,
                'X-VoucherCode': voucherCode,
                'X-BodySHA256': bodySHA256
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP Error Response: ${response.status}`);
        }

        return await response.json(); // Return the response data directly
    } catch (error) {
        console.error('Error calling Lambda function computeHMAC:', error);
        throw error; // Rethrow to handle it in the calling function
    }
}

export { computeHMAC };  // Export this function if it's used outside
