import React, { useState, useCallback } from 'react';
import {
  withAuthenticator,
  Button,
  Card,
  Heading,
  Flex,
  TextField,
  SelectField,
  useTheme,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@aws-amplify/ui-react';
import { computeHMAC } from './ApiUtils';  // Adjusted import
import "@aws-amplify/ui-react/styles.css";  // Ensure styles are imported

const BASE62_ALPHABET = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789";

const productionApiURL = "https://api.mintblank.com";
// const testApiURL = "https://api.givebit.io";
const usedApiURL = productionApiURL;

function isValidSerialNumber(serial) {
  const regex = new RegExp(`^[${BASE62_ALPHABET}]{12}$`);
  return regex.test(serial);
}

function convertLocalDateToISO8601UTC(dateString) {
  // Assuming dateString is in local time
  const localDate = new Date(dateString + "T00:00"); // Append time part to make it full datetime

  // Convert to UTC
  const isoString = localDate.toISOString();

  return isoString;
}

const getDefaultEndDate = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow.toISOString().split('T')[0]; // Format to YYYY-MM-DD
};

function App({ signOut }) {
  const [getVoucherResult, setGetVoucherResult] = useState(null);
  const [updateVoucherResult, setUpdateVoucherResult] = useState(null);
  const [getVoucherCodeResult, setGetVoucherCodeResult] = useState(null);
  const [patchVoucherCodeResult, setPatchVoucherCodeResult] = useState(null);
  const [getAdminVoucherResult, setAdminVoucherResult] = useState(null);
  const [getAdminSerialVoucherResult, setAdminSerialVoucherResult] = useState(null);
  const [getAdminCodeVoucherResult, setAdminCodeVoucherResult] = useState(null);
  const [getAdminVoucherPostResult, setAdminVoucherPostResult] = useState(null);
  const [transactionsResult, setTransactionsResult] = useState(null);
  const [refundResult, setRefundResult] = useState(null); // New state for refund result

  const [serialNumber, setSerialNumber] = useState('');
  const [serialNumberGet, setSerialNumberGet] = useState('');
  const [serialNumberFilter, setSerialNumberFilter] = useState('');

  const [trxId, setTrxId] = useState('');
  const [trxIdGet, setTrxIdGet] = useState('');
  const [voucherCode, setVoucherCode] = useState('');
  const [voucherCodeFilter, setVoucherCodeFilter] = useState('');
  const [spendFiatAmount, setSpendFiatAmount] = useState('');
  const [voucherState, setVoucherState] = useState('');
  const [productId, setProductId] = useState('ARI10V');
  const [startDate, setStartDate] = useState('2024-01-01');
  const [endDate, setEndDate] = useState(getDefaultEndDate());
  const [state, setState] = useState('');
  const [numberOfVouchers, setNumberOfVouchers] = useState('1');
  const [fiatAmount, setFiatAmount] = useState('100.00');
  const [currency, setCurrency] = useState('PLN');
  const [action, setAction] = useState('ALL ACTIONS');
  const [origin, setOrigin] = useState('ADM');

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const { tokens } = useTheme();


  const renderPaginationControls = (totalItems, currentPage, setCurrentPage) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pageNumbers = [];
  
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  
    return (
      <Flex justifyContent="center" margin="1rem">
        {pageNumbers.map(number => (
          <Button
            key={number}
            onClick={() => setCurrentPage(number)}
            variation={number === currentPage ? 'primary' : 'link'}
          >
            {number}
          </Button>
        ))}
      </Flex>
    );
  };

  const displayPaginatedItems = (items, currentPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return items.slice(startIndex, endIndex);
  };

  const convertArrayToCSV = (data) => {
    const csvRows = [];
    // Get the headers
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));
  
    // Loop over the rows
    for (const row of data) {
      const values = headers.map(header => {
        const escaped = (''+row[header]).replace(/"/g, '\\"'); // handle quotes
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }
  
    return csvRows.join('\n');
  };

  const downloadCSV = (data) => {
    const csvData = convertArrayToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'vouchers.csv';
    link.click();
    window.URL.revokeObjectURL(url);
  };
  
  const handleAmountChange = (event) => {
    let value = parseFloat(event.target.value).toFixed(2);
    setSpendFiatAmount(value);
  };
  
  const handleFiatAmountChange = (event) => {
    let value = parseFloat(event.target.value).toFixed(2);
    setFiatAmount(value);
  };
  const convertFloatToInt = (floatValue) => {
    return Math.round(floatValue * 100);
  };

  const lambdaUrl = 'https://auth.mintblank.com/auth';

  const handleComputeHMAC = useCallback(async () => {
    if (!serialNumber) {
      alert("Please enter a valid serial number.");
      return;
    }
    if (!isValidSerialNumber(serialNumber)) {
      alert(`Invalid serial number. Correct one consists of 12 characters from: ${BASE62_ALPHABET}.`);
      return;
    }
    const apiUrl = usedApiURL + `/v1/distributor/voucher/${serialNumber}`;
    try {
      const headers = await computeHMAC(lambdaUrl, apiUrl, '', '');  // Call computeHMAC directly
      makeSecondApiCall(apiUrl, headers);  // Assume headers are correctly formatted for the API call
    } catch (error) {
      console.error('Error retrieving headers from Lambda:', error);
    }
  }, [lambdaUrl, serialNumber]);

  const handleUpdateVoucherState = useCallback(async () => {
    if (!serialNumber || !isValidSerialNumber(serialNumber)) {
      alert(`Invalid serial number. Correct one consists of 12 characters from: ${BASE62_ALPHABET}.`);
      return;
    }
    if (!voucherState) {
      alert("Please select a voucher state.");
      return;
    }
    const apiUrl = usedApiURL + `/v1/distributor/voucher/${serialNumber}`;
    const body = { state: voucherState };
    try {
      const headers = await computeHMAC(lambdaUrl, apiUrl, '', body, 'PATCH');
      await makeSecondApiCall(apiUrl, headers, body, 'PATCH');
    } catch (error) {
      console.error('Error updating voucher state:', error);
    }
  }, [lambdaUrl, serialNumber, voucherState]);

  const handleGetVoucherCode = useCallback(async () => {
    if (!voucherCode) {
      alert("Please enter a valid voucherCode.");
      return;
    }

    if (!/^\d{16}$/.test(voucherCode)) {
      alert("Voucher Code must be exactly 16 digits.");
      return;
    }

    const apiUrl = usedApiURL + `/v1/operator/voucher/code`;
    try {
      const headers = await computeHMAC(lambdaUrl, apiUrl, voucherCode, '');  // Call computeHMAC directly
      makeSecondApiCall(apiUrl, headers);  // Assume headers are correctly formatted for the API call
    } catch (error) {
      console.error('Error retrieving headers from Lambda:', error);
    }
  }, [lambdaUrl, voucherCode]);

  const handlePatchVoucherCode = useCallback(async () => {
    if (!voucherCode) {
      alert("Please enter a valid voucherCode.");
      return;
    }

    if (!/^\d{16}$/.test(voucherCode)) {
      alert("Voucher Code must be exactly 16 digits.");
      return;
    }

    if(!spendFiatAmount){
      alert("Please enter a valid spendFiatAmount.");
      return;
    }

    const floatAmount = parseFloat(spendFiatAmount);
    const intAmount = convertFloatToInt(floatAmount);
    const body = {
      spendFiatAmount: intAmount.toString()
    };

    const apiUrl = usedApiURL + `/v1/operator/voucher/code`;
    try {
      const headers = await computeHMAC(lambdaUrl, apiUrl, voucherCode, body);  // Call computeHMAC directly
      makeSecondApiCall(apiUrl, headers, body, 'PATCH');  // Assume headers are correctly formatted for the API call
    } catch (error) {
      console.error('Error retrieving headers from Lambda:', error);
    }
  }, [lambdaUrl, voucherCode, spendFiatAmount]);

  const handleGetAdminVoucherResult = useCallback(async () => {
    if (serialNumberFilter) {
      if (!isValidSerialNumber(serialNumberFilter)) {
        alert(`Invalid serial number. Correct one consists of 12 characters from: ${BASE62_ALPHABET}.`);
        return;
      }
      const apiUrl = usedApiURL + `/v1/admin/voucher/${serialNumberFilter}`;
      try {
        const headers = await computeHMAC(lambdaUrl, apiUrl, '', '');  // Call computeHMAC directly
        makeSecondApiCall(apiUrl, headers);  // Assume headers are correctly formatted for the API call
        setCurrentPage(1);
        return;
      } catch (error) {
        console.error('Error retrieving headers from Lambda:', error);
      }
    } else if (voucherCodeFilter) {
      if (!/^\d{16}$/.test(voucherCodeFilter)) {
        alert("Voucher Code must be exactly 16 digits.");
        return;
      }
      const apiUrl = usedApiURL + `/v1/admin/voucher/code`;
      try {
        const headers = await computeHMAC(lambdaUrl, apiUrl, voucherCodeFilter, '');  // Call computeHMAC directly
        makeSecondApiCall(apiUrl, headers);  // Assume headers are correctly formatted for the API call
        setCurrentPage(1);
        return;
      } catch (error) {
        console.error('Error retrieving headers from Lambda:', error);
      }
    } else {
      if (!productId) {
        alert("Please enter a productId");
        return;
      }
      if (!startDate) {
        alert("Please enter a startDate");
        return;
      }
      if (!endDate) {
        alert("Please enter an endDate");
        return;
      }

      const startDateISO8601 = convertLocalDateToISO8601UTC(startDate);
      const endDateISO8601 = convertLocalDateToISO8601UTC(endDate);

      const queryParams = new URLSearchParams({
        productId: productId,
        startDate: startDateISO8601,
        endDate: endDateISO8601,
        state: state
      });

      // Remove any keys with empty values to avoid sending empty parameters
      for (const [key, value] of queryParams) {
        if (!value) queryParams.delete(key);
      }

      const apiUrl = usedApiURL + `/v1/admin/voucher?${queryParams.toString()}`;
      try {
        const headers = await computeHMAC(lambdaUrl, apiUrl, '', '');  // Call computeHMAC directly
        makeSecondApiCall(apiUrl, headers);  // Assume headers are correctly formatted for the API call
        setCurrentPage(1);
      } catch (error) {
        console.error('Error retrieving headers from Lambda:', error);
      }
    }
  }, [lambdaUrl, serialNumberFilter, voucherCodeFilter, productId, startDate, endDate, state]);

  const handlePostAdminVoucherResult = useCallback(async () => {
    if(!productId){
      alert("Please enter a productId");
      return;
    }
    if(!fiatAmount){
      alert("Please enter a fiatAmount");
      return;
    }
    if(!currency){
      alert("Please enter a currency");
      return;
    }
    if(!numberOfVouchers){
      alert("Please enter a numberOfVouchers");
      return;
    }
    const floatAmount = parseFloat(fiatAmount);
    const intAmount = convertFloatToInt(floatAmount);

    const body = {
      productId: productId,
      fiatAmount: intAmount.toString(),
      currency: currency,
      numberOfVouchers: numberOfVouchers
    };
  
    const apiUrl = usedApiURL + `/v1/admin/voucher`;
    try {
      const headers = await computeHMAC(lambdaUrl, apiUrl, '', body);  // Call computeHMAC directly
      makeSecondApiCall(apiUrl, headers, body, 'POST');  // Assume headers are correctly formatted for the API call
    } catch (error) {
      console.error('Error retrieving headers from Lambda:', error);
    }
  }, [lambdaUrl, productId, fiatAmount, currency, numberOfVouchers]);

  const handleGetTransactions = useCallback(async () => {
    if(!productId){
      alert("Please enter a productId");
      return;
    }
    if(!startDate){
      alert("Please enter a startDate");
      return;
    }
    if(!endDate){
      alert("Please enter a endDate");
      return;
    }
    var actionSent = "";
    if(action !== "ALL ACTIONS"){
      actionSent = action;
    }

    const startDateISO8601 = convertLocalDateToISO8601UTC(startDate);
    const endDateISO8601 = convertLocalDateToISO8601UTC(endDate);

    const queryParams = new URLSearchParams({
      serialNumber: serialNumberGet,
      productId: productId,
      action: actionSent,
      startDate: startDateISO8601,
      endDate: endDateISO8601,
      origin: origin
    });

    // Remove any keys with empty values to avoid sending empty parameters
    for (const [key, value] of queryParams) {
      if (!value) queryParams.delete(key);
    }

    var apiUrl = usedApiURL + `/v1/admin/trx/${trxIdGet}?${queryParams.toString()}`;
    if(!trxIdGet){
      apiUrl = usedApiURL + `/v1/admin/trx?${queryParams.toString()}`;
    }

    try {
      const headers = await computeHMAC(lambdaUrl, apiUrl, '', '');  // Call computeHMAC directly
      makeSecondApiCall(apiUrl, headers);  // Assume headers are correctly formatted for the API call
      setCurrentPage(1);
    } catch (error) {
      console.error('Error retrieving headers from Lambda:', error);
    }
  }, [lambdaUrl, productId, trxIdGet, serialNumberGet, action, startDate, endDate, origin]);

  const handleRefundTransaction = useCallback(async () => {
    if (!trxId) {
      alert("Please enter a valid trxId.");
      return;
    }
    const apiUrl = usedApiURL + `/v1/admin/trx/${trxId}/refund`;
    try {
      const headers = await computeHMAC(lambdaUrl, apiUrl, '', '');  // Call computeHMAC directly
      makeSecondApiCall(apiUrl, headers, '', 'POST');  // Assume headers are correctly formatted for the API call
    } catch (error) {
      console.error('Error processing refund:', error);
    }
  }, [lambdaUrl, trxId]);

  const makeSecondApiCall = async (apiUrl, headers, body = '', method = 'GET') => {
    try {
      if (apiUrl.includes('distributor/voucher') && method === 'GET') {
        const response = await fetch(apiUrl, {
          method: method,
          headers: headers,
        });
        const responseData = await response.json();
        setGetVoucherResult(responseData);
      } else if (apiUrl.includes('distributor/voucher') && method === 'PATCH') {
        const response = await fetch(apiUrl, {
          method: method,
          headers: headers,
          body: JSON.stringify(body)
        });
        const responseData = await response.json();
        setUpdateVoucherResult(responseData);
      } else if (apiUrl.includes('operator/voucher/code') && method === 'GET') {
        const response = await fetch(apiUrl, {
          method: method,
          headers: headers,
        });
        const responseData = await response.json();
        setGetVoucherCodeResult(responseData);
      } else if (apiUrl.includes('operator/voucher/code') && method === 'PATCH') {
        const response = await fetch(apiUrl, {
          method: method,
          headers: headers,
          body: JSON.stringify(body)
        });
        const responseData = await response.json();
        setPatchVoucherCodeResult(responseData);
      } else if (apiUrl.includes('admin/voucher/code') && method === 'GET') {
        console.log(apiUrl);
        const response = await fetch(apiUrl, {
          method: method,
          headers: headers,
        });
        const responseData = await response.json();
        setAdminSerialVoucherResult(null);
        setAdminVoucherResult(null);
        setAdminCodeVoucherResult(responseData);
      } else if (apiUrl.includes('admin/voucher/') && serialNumberFilter && method === 'GET') {
        console.log(apiUrl);
        const response = await fetch(apiUrl, {
          method: method,
          headers: headers,
        });
        const responseData = await response.json();
        setAdminCodeVoucherResult(null);
        setAdminVoucherResult(null);
        setAdminSerialVoucherResult(responseData);
      } else if (apiUrl.includes('admin/voucher') && method === 'GET') {
        console.log(apiUrl);
        const response = await fetch(apiUrl, {
          method: method,
          headers: headers,
        });
        const responseData = await response.json();
        setAdminSerialVoucherResult(null);
        setAdminCodeVoucherResult(null);
        setAdminVoucherResult(responseData);
      } else if (apiUrl.includes('admin/voucher') && method === 'POST') {
        console.log(apiUrl);
        const response = await fetch(apiUrl, {
          method: method,
          headers: headers,
          body: JSON.stringify(body)
        });
        const responseData = await response.json();
        setAdminVoucherPostResult(responseData);
      } else if (apiUrl.includes('admin/trx') && method === 'GET') {
        console.log(apiUrl);
        const response = await fetch(apiUrl, {
          method: method,
          headers: headers,
        });
        const responseData = await response.json();
        console.log(responseData);
        // Convert timestamps to ISO 8601 format
        if (Array.isArray(responseData) && responseData.length > 0) {
          // Convert timestamps to ISO 8601 format if responseData is not empty
          const transformedData = responseData.map(item => ({
            ...item,
            timestamp: new Date(item.timestamp).toISOString()
          }));
          setTransactionsResult(transformedData);
        } else {
          // Handle empty responseData
          setTransactionsResult([{message: "No transactions found."}]);
        }
      } else if (apiUrl.includes('admin/trx') && method === 'POST') {
        console.log(apiUrl);
        const response = await fetch(apiUrl, {
          method: method,
          headers: headers
        });
        const responseData = await response.json();
        setRefundResult(responseData);
      }
    } catch (error) {
      console.error('Error making API call:', error);
    }
  };

  return (
    <Flex direction="column" gap="1rem" padding="1rem" styles={{ maxWidth: 800, margin: '0 auto' }}>
      <Flex justifyContent="space-between" alignItems="center" padding="1rem" backgroundColor={tokens.colors.background.primary} style={{ borderRadius: 10 }}>
        <Heading level={1}>MintBlank Admin Panel </Heading>
        <Button variation="primary" onClick={signOut}>Sign Out</Button>
      </Flex>

      <Card variation="elevated" padding="medium" style={{ marginBottom: '20px' }}>
        <Heading level={4} style={{ marginBottom: '15px' }}>Retrieve voucher by serial number</Heading>
        <Flex direction="column" gap="0.5rem">
          <TextField
            label="Serial Number"
            placeholder="Enter Serial Number"
            onChange={(event) => setSerialNumber(event.target.value)}
            value={serialNumber}
            autoComplete="off"
          />
          <Button onClick={handleComputeHMAC} variation="primary">Get Voucher</Button>
          {getVoucherResult && (
            <Table highlightOnHover variation="striped">
              <TableHead>
                <TableRow>
                  <TableCell scope="col">Property</TableCell>
                  <TableCell scope="col">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(getVoucherResult).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Flex>
      </Card>

      <Card variation="elevated" padding="medium" style={{ marginBottom: '20px' }}>
        <Heading level={4} style={{ marginBottom: '15px' }}>Update voucher state by serial number</Heading>
        <Flex direction="column" gap="0.5rem">
          <TextField
            label="Serial Number"
            placeholder="Enter Serial Number"
            onChange={(event) => setSerialNumber(event.target.value)}
            value={serialNumber}
            autoComplete="off"
          />
          <select
            onChange={(event) => setVoucherState(event.target.value)}
            value={voucherState}
          >
            <option value="">Select State</option>
            <option value="ACTIVE">ACTIVE</option>
            <option value="CANCELED">CANCELED</option>
          </select>
          <Button onClick={handleUpdateVoucherState} variation="primary">Update Voucher State</Button>
          {updateVoucherResult && (
            <Table highlightOnHover variation="striped">
               <TableHead>
                <TableRow>
                  <TableCell scope="col">Property</TableCell>
                  <TableCell scope="col">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(updateVoucherResult).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Flex>
      </Card>

      <Card variation="elevated" padding="medium" style={{ marginBottom: '20px' }}>
        <Heading level={4} style={{ marginBottom: '15px' }}>Retrieve voucher by voucher code</Heading>
        <Flex direction="column" gap="0.5rem">
          <TextField
            label="Voucher Code"
            placeholder="Enter Voucher Code Number"
            onChange={(event) => setVoucherCode(event.target.value)}
            value={voucherCode}
            autoComplete="off"
          />
          <Button onClick={handleGetVoucherCode} variation="primary">Get Voucher</Button>
          {getVoucherCodeResult && (
            <Table highlightOnHover variation="striped">
              <TableHead>
                <TableRow>
                  <TableCell scope="col">Property</TableCell>
                  <TableCell scope="col">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(getVoucherCodeResult).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Flex>
      </Card>

      <Card variation="elevated" padding="medium" style={{ marginBottom: '20px' }}>
        <Heading level={4} style={{ marginBottom: '15px' }}>Spend voucher by voucher code</Heading>
        <Flex direction="column" gap="0.5rem">
          <Flex direction="row" justifyContent="space-between">
            <Flex direction="column" gap="0.5rem" style={{ flex: 1, marginRight: '1rem' }}>
              <TextField
                label="Voucher Code"
                placeholder="Enter Voucher Code Number"
                onChange={(event) => setVoucherCode(event.target.value)}
                value={voucherCode}
                autoComplete="off"
              />
            </Flex>
            <Flex direction="column" gap="0.5rem" style={{ flex: 1 }}>
              <TextField
                label="Spend Amount"
                placeholder="100.00"
                onChange={(event) => handleAmountChange(event)}
                value={spendFiatAmount}
                autoComplete="off"
              />
            </Flex>
          </Flex>
          <Button onClick={handlePatchVoucherCode} variation="primary">Spend Voucher</Button>
          {patchVoucherCodeResult && (
            <Table highlightOnHover variation="striped">
              <TableHead>
                <TableRow>
                  <TableCell scope="col">Property</TableCell>
                  <TableCell scope="col">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(patchVoucherCodeResult).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Flex>
      </Card>

      <Card variation="elevated" padding="medium" style={{ marginBottom: '20px' }}>
        <Heading level={4} style={{ marginBottom: '15px' }}>Get vouchers with filters</Heading>
        <Flex direction="column" gap="0.5rem">
          {/*
          <TextField
            label="Product ID"
            placeholder="Enter Product ID"
            onChange={(event) => setProductId(event.target.value)}
            value={productId}
            autoComplete="off"
          />
          */}
           <TextField
            label="Serial Number"
            placeholder="Enter Serial Number"
            onChange={(event) => setSerialNumberFilter(event.target.value)}
            value={serialNumberFilter}
            autoComplete="off"
            disabled={!!voucherCodeFilter}
          />
           <TextField
            label="Voucher Code"
            placeholder="Enter Voucher Code Number"
            onChange={(event) => setVoucherCodeFilter(event.target.value)}
            value={voucherCodeFilter}
            autoComplete="off"
            disabled={!!serialNumberFilter}
          />
          <TextField
            label="Start Date"
            placeholder="YYYY-MM-DD"
            onChange={(event) => setStartDate(event.target.value)}
            value={startDate}
            type="date"
            disabled={!!serialNumberFilter || !!voucherCodeFilter}
          />
          <TextField
            label="End Date"
            placeholder="YYYY-MM-DD"
            onChange={(event) => setEndDate(event.target.value)}
            value={endDate}
            type="date"
            disabled={!!serialNumberFilter || !!voucherCodeFilter}
          />
          <select
            onChange={(event) => setState(event.target.value)}
            value={state}
            disabled={!!serialNumberFilter || !!voucherCodeFilter}
          >
            <option value="">ALL STATES</option>
            <option value="INACTIVE">INACTIVE</option>
            <option value="ACTIVE">ACTIVE</option>
            <option value="CANCELED">CANCELED</option>
            <option value="SPENT">SPENT</option>
            <option value="EXPIRED">EXPIRED</option>
          </select>
          <Button onClick={handleGetAdminVoucherResult} variation="primary">Get Vouchers</Button>
          {getAdminSerialVoucherResult && (
            <>
              <Button onClick={() => downloadCSV([getAdminSerialVoucherResult])} variation="primary">Download CSV</Button>
              <Table highlightOnHover variation="striped">
                <TableHead>
                  <TableRow>
                    {Object.keys(getAdminSerialVoucherResult || {}).map((header) => (
                      <TableCell key={header}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(getAdminSerialVoucherResult).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell>{key}</TableCell>
                      <TableCell>{value.toString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}

          {getAdminCodeVoucherResult && (
            <>
              <Button onClick={() => downloadCSV([getAdminCodeVoucherResult])} variation="primary">Download CSV</Button>
              <Table highlightOnHover variation="striped">
                <TableHead>
                  <TableRow>
                    <TableCell scope="col">Property</TableCell>
                    <TableCell scope="col">Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(getAdminCodeVoucherResult).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell>{key}</TableCell>
                      <TableCell>{value.toString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}

          {getAdminVoucherResult && getAdminVoucherResult.length > 0 && (
            <>
              <Button onClick={() => downloadCSV(getAdminVoucherResult)} variation="primary">Download CSV</Button>
              <Table highlightOnHover variation="striped">
                <TableHead>
                  <TableRow>
                    {Object.keys(getAdminVoucherResult[0] || {}).map((header) => (
                      <TableCell key={header}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayPaginatedItems(getAdminVoucherResult, currentPage).map((item, index) => (
                    <TableRow key={index}>
                      {Object.entries(item).map(([key, value]) => (
                        <TableCell key={key}>{value.toString()}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {renderPaginationControls(getAdminVoucherResult.length, currentPage, setCurrentPage)}
            </>
          )}



        </Flex>
      </Card>

      <Card variation="elevated" padding="medium" style={{ marginBottom: '20px' }}>
        <Heading level={4} style={{ marginBottom: '15px' }}>Create voucher</Heading>
        <Flex direction="column" gap="0.5rem">
          {/*
          <TextField
            label="Product ID"
            placeholder="Enter Product ID"
            onChange={(event) => setProductId(event.target.value)}
            value={productId}
            autoComplete="off"
          />
          */}
          <Flex direction="column" gap="0.5rem" style={{ flex: 1 }}>
            <TextField
              label="Voucher Amount"
              placeholder="100.00"
              onChange={(event) => handleFiatAmountChange(event)}
              value={fiatAmount}
              autoComplete="off"
            />
          </Flex>
          <select
            onChange={(event) => setCurrency(event.target.value)}
            value={currency}
          >
            <option value="">Select Currency</option>
            <option value="PLN">PLN</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
          </select>
          <Flex direction="column" gap="0.5rem" style={{ flex: 1 }}>
            <TextField
              label="Voucher Number"
              placeholder="1"
              onChange={(event) => setNumberOfVouchers(event.target.value)}
              value={numberOfVouchers}
              autoComplete="off"
            />
          </Flex>
          <Button onClick={handlePostAdminVoucherResult} variation="primary">Create Vouchers</Button>
          {getAdminVoucherPostResult && getAdminVoucherPostResult.length > 0 && (
            <>
              <Button onClick={() => downloadCSV(getAdminVoucherPostResult)} variation="primary">Download CSV</Button>
              <Table highlightOnHover variation="striped">
                <TableHead>
                  <TableRow>
                    {Object.keys(getAdminVoucherPostResult[0] || {}).map((header) => (
                      <TableCell key={header}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getAdminVoucherPostResult.map((item, index) => (
                    <TableRow key={index}>
                      {Object.entries(item).map(([key, value]) => (
                        <TableCell key={key}>{value.toString()}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </Flex>
      </Card>

      <Card variation="elevated" padding="medium" style={{ marginBottom: '20px' }}>
        <Heading level={4} style={{ marginBottom: '15px' }}>Get transactions</Heading>
        <Flex direction="column" gap="0.5rem">
          <TextField
            label="Transaction Id"
            placeholder="Enter trxId"
            onChange={(event) => setTrxIdGet(event.target.value)}
            value={trxIdGet}
            autoComplete="off"
            disabled={!!serialNumberGet}
          />
          <TextField
            label="Serial Number"
            placeholder="Enter Serial Number"
            onChange={(event) => setSerialNumberGet(event.target.value)}
            value={serialNumberGet}
            autoComplete="off"
            disabled={!!trxIdGet}
          />
          <SelectField
            label="Action"
            onChange={(event) => setAction(event.target.value)}
            value={action}
            disabled={!!trxIdGet || !!serialNumberGet}
          >
            {["GENERATE", "ACTIVATE", "CANCEL", "SPEND", "EXPIRE", "ALL ACTIONS"].map(action => (
              <option key={action} value={action}>{action}</option>
            ))}
          </SelectField>
          <TextField
            label="Start Date"
            placeholder="YYYY-MM-DD"
            onChange={(event) => setStartDate(event.target.value)}
            value={startDate}
            type="date"
            disabled={!!trxIdGet || !!serialNumberGet}
          />
          <TextField
            label="End Date"
            placeholder="YYYY-MM-DD"
            onChange={(event) => setEndDate(event.target.value)}
            value={endDate}
            type="date"
            disabled={!!trxIdGet || !!serialNumberGet}
          />
          <TextField
            label="Origin"
            placeholder="Enter Origin"
            onChange={(event) => setOrigin(event.target.value)}
            value={origin}
            autoComplete="off"
            disabled={!!trxIdGet || !!serialNumberGet}
          />
          <Button onClick={handleGetTransactions} variation="primary">Get Transactions</Button>
          {transactionsResult && transactionsResult.length > 0 && (
            <>
              <Button onClick={() => downloadCSV(transactionsResult)} variation="primary">Download CSV</Button>
              <Table highlightOnHover variation="striped">
                <TableHead>
                  <TableRow>
                    {Object.keys(transactionsResult[0] || {}).map((header) => (
                      <TableCell key={header}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayPaginatedItems(transactionsResult, currentPage).map((item, index) => (
                    <TableRow key={index}>
                      {Object.entries(item).map(([key, value]) => (
                        <TableCell key={key}>{value.toString()}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {renderPaginationControls(transactionsResult.length, currentPage, setCurrentPage)}
            </>
          )}

        </Flex>
      </Card>

      <Card variation="elevated" padding="medium" style={{ marginBottom: '20px' }}>
        <Heading level={4} style={{ marginBottom: '15px' }}>Refund Transaction</Heading>
        <Flex direction="column" gap="0.5rem">
          <TextField
            label="Transaction Id"
            placeholder="Enter trxId"
            onChange={(event) => setTrxId(event.target.value)}
            value={trxId}
            autoComplete="off"
          />
          <Button onClick={handleRefundTransaction} variation="primary">Refund Transaction</Button>
          {refundResult && (
            <Table highlightOnHover variation="striped">
              <TableHead>
                <TableRow>
                  <TableCell scope="col">Property</TableCell>
                  <TableCell scope="col">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(refundResult).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Flex>
      </Card>
        
    </Flex>
  );
}

export default withAuthenticator(App);
